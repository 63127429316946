html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
body {
  background-color: #f6f5fa;
  color: #3d3942;
  font-family: 'Fira Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -mox-osx--font-smoothing: grayscale;
  font-size: 16px;
}
h1 {
  font-size: 36px;
  color: #2E1A47;
  line-height: 45px;
  font-weight: 400;
}
h2 {
  font-size: 27px;
  color: #2E1A47;
  line-height: 36px;
  font-weight: 500;
}
h3 {
  font-size: 22.5px;
  color: #2E1A47;
  letter-spacing: 0;
  line-height: 29.7px;
  font-weight: 500;
}
h4 {
  font-size: 18px;
  color: #303133;
  letter-spacing: 0;
  line-height: 27px;
  font-weight: 700;
}
h5 {
  font-size: 15.3px;
  color: #2E1A47;
  letter-spacing: 0.5px;
  line-height: 19.8px;
  text-transform: uppercase;
  font-weight: 400;
}
.hero-heading {
  font-size: 48px;
  color: #2E1A47;
  letter-spacing: 1px;
  line-height: 61px;
  font-weight: 700;
}
div {
  box-sizing: border-box;
}
input {
  box-sizing: border-box;
  width: 100%;
  padding: 6px 14px;
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid white;
  outline: none;
  font-family: 'Fira Sans', sans-serif;
  transition: border-color 0.2s;
  background-color: white;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
input::placeholder {
  color: #999999;
}
input:focus {
  border-color: #2e1a47;
}
.input-dim input {
  background-color: #f6f5fa;
  border-color: #f6f5fa;
}
.input-dim input:focus {
  border-color: #2e1a47;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
textarea {
  box-sizing: border-box;
  width: 100%;
  min-height: 60px;
  resize: vertical;
  padding: 6px 14px;
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid white;
  outline: none;
  font-family: 'Fira Sans', sans-serif;
  transition: border-color 0.2s;
  background-color: white;
}
textarea::placeholder {
  color: #999999;
}
textarea:focus {
  border-color: #2e1a47;
}
.input-dim textarea {
  background-color: #f6f5fa;
  border-color: #f6f5fa;
}
.input-dim textarea:focus {
  border-color: #2e1a47;
}
select {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 6px 14px;
  padding-right: 40px;
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid white;
  outline: none;
  font-family: 'Fira Sans', sans-serif;
  transition: border-color 0.2s;
  background-color: white;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
select:invalid {
  color: #999999;
}
select option[value=""] {
  color: #999999;
}
select:focus {
  border-color: #2e1a47;
}
.input-dim select {
  background-color: #f6f5fa;
  border-color: #f6f5fa;
}
.input-dim select:focus {
  border-color: #2e1a47;
}
p {
  font-size: 16px;
  color: #303133;
  line-height: 24px;
  font-weight: 400;
}
.screen {
  min-height: 100%;
}
.screen__content {
  position: relative;
  min-height: calc(100vh - 600px);
  z-index: 1;
}
.button {
  position: relative;
  display: inline-block;
  padding: 14px 20px;
  border-radius: 4px;
  text-align: center;
  background-color: #2e1a47;
  color: white;
  cursor: pointer;
  overflow: hidden;
  transition: background-color 0.2s;
}
.button:hover {
  background-color: #714ba0;
}
.button:focus {
  background-color: #2e1a47;
}
.button:active {
  text-decoration: underline;
}
.button--outline {
  background-color: transparent;
  border: 1px solid #2e1a47;
  color: #3d3942;
}
.button--outline:hover {
  background-color: #e7eae4;
}
.button--outline:focus {
  background-color: #d6dccf;
}
.button--outline:active {
  background-color: white;
}
.button--green {
  background-color: #6C8849;
}
.button--green:hover {
  background-color: #8bae5f;
}
.button--green:focus {
  background-color: #6C8849;
}
.button--grey {
  background-color: #999999;
}
.button--wide {
  min-width: 200px;
}
.button--full-width {
  width: 100%;
}
.button--inverse-primary {
  background-color: white;
  color: #2e1a47;
}
.header {
  position: relative;
  width: 100%;
  background-color: white;
  font-size: 16px;
  z-index: 20;
}
.header__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #edecf2;
}
.header__tagline {
  font-size: 18px;
  font-style: italic;
  text-align: right;
  font-weight: 400;
}
.header__logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 45px;
}
.header__logo-link {
  line-height: 0;
}
.header__logo {
  max-width: 100%;
  max-height: 80px;
  cursor: pointer;
}
.header__button {
  width: 128px;
  height: 128px;
  background-position: center;
  background-size: 32px;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: background-color 0.2s;
}
.header__button:hover {
  background-color: #B6B2CC;
}
.header__menu-button {
  display: none;
  background-image: url("/images/icons/menu-purple.svg");
}
.header__menu-button--open {
  background-image: url("/images/icons/cross-purple.svg");
}
.header__search-button {
  background-image: url("/images/icons/search-purple.svg");
}
.header__nav-item {
  min-height: 90px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: background-color 0.2s;
  color: #2e1a47;
  text-decoration: none;
  padding: 0 10px;
  background-color: white;
  font-size: 18px;
  box-sizing: border-box;
}
.header__nav-item:hover {
  background-color: #edecf2;
}
.header__nav-item + .header__nav-item {
  border-left: 1px solid #edecf2;
}
.header__nav-item--expandable {
  position: relative;
}
.header__nav-item--expandable:hover {
  background-color: white;
}
.header__nav-item__dropdown-arrow {
  width: 12px;
  margin-left: 6px;
}
.header__nav-item__dropdown {
  display: none;
  position: absolute;
  left: -1px;
  right: -1px;
  top: 100%;
  border: 1px solid #edecf2;
}
.header__nav-item--expandable:hover .header__nav-item__dropdown,
.header__nav-item--expandable:focus .header__nav-item__dropdown {
  display: block;
}
.header__nav-item__dropdown__item {
  display: flex;
  min-height: 75px;
  padding: 20px;
  text-align: left;
  align-items: center;
  background-color: white;
  text-decoration: none;
  color: #2e1a47;
  border-bottom: 1px solid #edecf2;
  font-size: 15px;
  box-sizing: border-box;
  transition: background-color 0.2s;
}
.header__nav-item__dropdown__item:hover {
  background-color: #edecf2;
}
@media only screen and (max-width: 960px) {
  .header {
    height: 115px;
  }
  .header__logo-wrapper {
    padding: 0;
  }
  .header__logo {
    max-height: 70px;
    margin: 0;
  }
  .header__tagline {
    display: none;
  }
  .header__button {
    width: 115px;
    height: 115px;
  }
  .header__menu-button {
    display: block;
  }
  .header__row--nav {
    display: none;
  }
}
@media only screen and (max-width: 640px) {
  .header {
    height: 50px;
  }
  .header__button {
    width: 50px;
    height: 50px;
    background-size: 20px;
  }
  .header__logo-wrapper {
    height: 50px;
    min-height: 50px;
  }
  .header__logo {
    max-height: 42px;
  }
}
.footer {
  position: relative;
  background-image: url("/images/footer-background.svg");
  background-position: top;
  background-size: cover;
  color: white;
  z-index: 10;
}
.footer__content {
  padding: 30px 24px;
  padding-top: 50px;
}
.footer__block {
  margin-bottom: 10px;
}
.footer__title {
  font-size: 18px;
  color: #B6B2CC;
  margin-top: 0;
  margin-bottom: 14px;
  font-weight: 600;
}
.footer__link {
  display: block;
  margin: 10px 0;
  color: white;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
  transition: opacity 0.2s;
}
.footer__link:hover {
  opacity: 0.6;
}
.footer__text {
  color: white;
  font-size: 18px;
  font-weight: 400;
}
.footer__link--icon {
  padding-left: 30px;
  background-size: 24px;
  background-position: left center;
  background-repeat: no-repeat;
}
.footer__link--facebook-icon {
  background-image: url("/images/social-icons/facebook-white.svg");
}
.footer__link--twitter-icon {
  background-image: url("/images/social-icons/twitter-white.svg");
}
.footer__link--linkedin-icon {
  background-image: url("/images/social-icons/linkedin-white.svg");
}
.footer__partner-logo {
  width: 120px;
}
.footer__feedback-row {
  padding: 24px;
  text-align: center;
  background-color: #2e1a47;
}
.footer__feedback-row a {
  color: white;
  transition: opacity 0.2s;
}
.footer__feedback-row a:hover {
  opacity: 0.5;
}
.footer__feedback-row__text {
  margin: 0;
  font-weight: 400;
}
@media only screen and (max-width: 960px) {
  .footer__text {
    font-size: 16px;
  }
  .footer__link {
    font-size: 16px;
  }
}
.cover {
  width: 100%;
  color: white;
  background-image: url('/images/fishing.jpg');
  background-position: center, center;
  background-size: cover;
}
.section {
  width: 100%;
  padding: 50px 30px;
}
.section--padding-small {
  padding-top: 20px;
  padding-bottom: 20px;
}
.section__content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.section--background-icons {
  background-image: url("/images/background-icons/innovation-light-grey.svg"), url("/images/background-icons/health-light-grey.svg"), url("/images/background-icons/finance-light-grey.svg"), url("/images/background-icons/people-light-grey.svg"), url("/images/background-icons/clipboard-light-grey.svg");
  background-size: 200px,
    100px,
    200px,
    200px,
    160px;
  background-position: -60px -30px, 50px 90%, 100% -30px, 60% 105%, 95% 90%;
  background-repeat: no-repeat;
}
.section__content--extra-small {
  max-width: 400px;
}
.section__content--small {
  max-width: 600px;
}
.section__content--medium {
  max-width: 800px;
}
.section__content--large {
  max-width: 960px;
}
.section__content--extra-large {
  max-width: 1200px;
}
@media only screen and (max-width: 800px) {
  .section--background-icons {
    background-image: url("/images/background-icons/innovation-light-grey.svg"), url("/images/background-icons/finance-light-grey.svg"), url("/images/background-icons/people-light-grey.svg");
    background-size: 200px,
      200px,
      200px;
    background-position: -60px -50px, 110% 50%, -20px 98%;
  }
}
.flex {
  display: flex;
}
.flex--column {
  flex-direction: column;
}
.flex--center {
  align-items: center;
  justify-content: center;
}
.flex__1 {
  flex: 1;
}
.flex__2 {
  flex: 2;
}
.flex__3 {
  flex: 3;
}
.flex--align-start {
  align-items: flex-start;
}
.flex--align-end {
  align-items: flex-end;
}
.flex--align-center {
  align-items: center;
}
.flex--align-stretch {
  align-items: stretch;
}
.flex--align-baseline {
  align-items: baseline;
}
.flex--justify-start {
  justify-content: flex-start;
}
.flex--justify-end {
  justify-content: flex-end;
}
.flex--justify-center {
  justify-content: center;
}
.flex--justify-between {
  justify-content: space-between;
}
.flex--justify-around {
  justify-content: space-around;
}
.flex--justify-evenly {
  justify-content: space-evenly;
}
.column-container {
  display: flex;
}
.column-container--align-center {
  align-items: center;
}
.column-container--align-end {
  align-items: flex-end;
}
.column-container--align-stretch {
  align-items: stretch;
}
.column-container--wrap {
  flex-wrap: wrap;
}
.column-container--justify-center {
  justify-content: center;
}
.column-container--justify-space-between {
  justify-content: space-between;
}
@media only screen and (max-width: 400px) {
  .column-container--extra-small-cut-off {
    flex-direction: column;
    align-items: center;
  }
  .column-container--extra-small-cut-off > .column {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (max-width: 600px) {
  .column-container--small-cut-off {
    flex-direction: column;
    align-items: center;
  }
  .column-container--small-cut-off > .column {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (max-width: 800px) {
  .column-container--medium-cut-off {
    flex-direction: column;
    align-items: center;
  }
  .column-container--medium-cut-off > .column {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (max-width: 960px) {
  .column-container--large-cut-off {
    flex-direction: column;
    align-items: center;
  }
  .column-container--large-cut-off > .column {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (max-width: 1200px) {
  .column-container--extra-large-cut-off {
    flex-direction: column;
    align-items: center;
  }
  .column-container--extra-large-cut-off > .column {
    padding-left: 0;
    padding-right: 0;
  }
}
.form__field {
  margin-top: 5px;
  margin-bottom: 20px;
}
.form__field--ignored {
  opacity: 0.2;
}
.check-box {
  position: relative;
  display: flex;
  margin: 5px 0;
  cursor: pointer;
  align-items: top;
}
.check-box--no-button-transition {
  cursor: default;
}
.check-box__tick-box {
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-top: 2px;
  border: 2px solid #999999;
  border-radius: 6px;
  cursor: pointer;
  transition: border-color 0.2s;
}
.check-box__tick-box:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 5px;
  top: 3px;
  left: 2px;
  border-left: 2px solid #999999;
  border-bottom: 2px solid #999999;
  transform: rotate(-40deg) scale(0) translate(2px);
  transition: border-color 0.2s, transform 0.2s;
}
.check-box__tick-box--active {
  border-color: #2e1a47;
}
.check-box__tick-box--active:after {
  border-color: #2e1a47;
  transform: rotate(-50deg) scale(1);
}
.check-box__text {
  flex: 1;
  color: #999999;
}
@media only screen and (max-width: 800px) {
  .check-box:not(.check-box--no-button-transition) {
    display: block;
  }
  .check-box:not(.check-box--no-button-transition) .check-box__text {
    width: 100%;
    position: relative;
    display: inline-block;
    padding: 14px 20px;
    border-radius: 4px;
    text-align: center;
    background-color: #2e1a47;
    color: white;
    cursor: pointer;
    overflow: hidden;
    background-color: #999999;
    transition: background-color 0.2s;
  }
  .check-box:not(.check-box--no-button-transition) .check-box__text:hover {
    background-color: #714ba0;
  }
  .check-box:not(.check-box--no-button-transition) .check-box__text:focus {
    background-color: #2e1a47;
  }
  .check-box:not(.check-box--no-button-transition) .check-box__text:active {
    text-decoration: underline;
  }
  .check-box:not(.check-box--no-button-transition) .check-box__text--active {
    background-color: #2e1a47;
  }
  .check-box:not(.check-box--no-button-transition) .check-box__tick-box {
    position: absolute;
    top: -5px;
    right: -5px;
    margin: 0;
    background-color: white;
    z-index: 1;
  }
}
.radio {
  display: flex;
  margin: 5px 0;
  cursor: pointer;
  align-items: top;
}
.radio__dot {
  position: relative;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  margin-top: 2px;
  border-radius: 100%;
  border: 2px solid #999999;
  box-sizing: content-box;
  transition: border-color 0.2s;
}
.radio__dot:after {
  content: '';
  position: absolute;
  width: 14px;
  height: 14px;
  top: 1px;
  left: 1px;
  background-color: #999999;
  border-radius: 100%;
  transform: scale(0);
  transition: background-color 0.2s, transform 0.2s;
}
.radio__dot--active {
  border-color: #2e1a47;
}
.radio__dot--active:after {
  background-color: #2e1a47;
  transform: scale(1);
}
.radio__text {
  flex: 1;
  color: #999999;
}
@media only screen and (max-width: 800px) {
  .radio {
    display: block;
  }
  .radio__text {
    width: 100%;
    position: relative;
    display: inline-block;
    padding: 14px 20px;
    border-radius: 4px;
    text-align: center;
    background-color: #2e1a47;
    color: white;
    cursor: pointer;
    overflow: hidden;
    background-color: #999999;
    transition: background-color 0.2s;
  }
  .radio__text:hover {
    background-color: #714ba0;
  }
  .radio__text:focus {
    background-color: #2e1a47;
  }
  .radio__text:active {
    text-decoration: underline;
  }
  .radio__text--active {
    background-color: #2e1a47;
  }
  .radio__dot {
    display: none;
  }
}
.error-message {
  color: #a94242;
  font-size: 16px;
  margin: 5px 0;
}
.form-section-navigation {
  width: 250px;
  background-color: #999999;
  overflow: hidden;
  border-radius: 5px;
  color: white;
}
.form-section-navigation__item {
  position: relative;
  width: 100%;
  font-size: 16px;
  padding: 10px 20px;
  text-align: center;
  transition: background-color 0.2s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.form-section-navigation__item--complete {
  background-color: #303133;
}
.form-section-navigation__item--incomplete {
  background-color: #a94242;
}
.form-section-navigation__item--active {
  background-color: #2e1a47;
}
.box {
  background-color: white;
  border-radius: 5px;
}
.box--offset-outline {
  position: relative;
}
.box--offset-outline:after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: -10px;
  left: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  border: 5px solid #2e1a47;
  border-radius: 10px;
}
.progress-bar {
  height: 2px;
  background-color: rgba(0, 0, 0, 0.1);
}
.progress-bar__progress {
  height: 2px;
  background-color: #2e1a47;
  transition: width 0.5s;
}
.input-wrapper {
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
}
.input-wrapper__input {
  flex: 1;
  height: 40px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: none;
}
.input-wrapper__input:focus + .input-wrapper__icon {
  background-color: #2e1a47;
}
.input-wrapper__icon {
  width: 40px;
  height: 40px;
  background-color: #999999;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  transition: background-color 0.2s;
}
.input-wrapper__icon--arrow-icon {
  background-image: url('/images/icons/down-white.svg');
  background-position: center;
  background-size: 30%;
  background-repeat: no-repeat;
}
.input-wrapper__icon--calendar-icon {
  background-image: url('/images/icons/calendar-white.svg');
  background-position: center;
  background-size: 60%;
  background-repeat: no-repeat;
}
.input-wrapper__icon--click-through {
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
}
.input-wrapper__icon--split {
  display: flex;
  flex-direction: column;
}
.input-wrapper__icon__split {
  flex: 1;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.2s;
}
.input-wrapper__icon__split:hover {
  background-color: #2e1a47;
}
.input-wrapper__icon__split + .input-wrapper__icon__split {
  border-top: 1px solid white;
}
.input-dim .input-wrapper__icon__split + .input-wrapper__icon__split {
  border-top-color: rgba(0, 0, 0, 0.2);
}
.input-wrapper__icon__split--up-arrow {
  background-image: url('/images/icons/down-white.svg');
  background-position: center;
  background-size: 30%;
  background-repeat: no-repeat;
  transform: rotate(180deg);
}
.input-wrapper__icon__split--down-arrow {
  background-image: url('/images/icons/down-white.svg');
  background-position: center;
  background-size: 30%;
  background-repeat: no-repeat;
}
.icon-selector {
  width: 200px;
  display: inline-block;
  vertical-align: top;
  padding: 10px;
  cursor: pointer;
}
.icon-selector:hover .icon-selector__icon {
  border-width: 2px;
}
.icon-selector:hover .icon-selector__icon--active {
  border-width: 4px;
}
.icon-selector__icon {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  border: 0px solid #2e1a47;
  background-color: white;
  margin: 0 auto;
  background-position: center;
  background-size: 70%;
  background-repeat: no-repeat;
  transition: border-width 0.1s;
}
.icon-selector__icon--active {
  border-width: 4px;
}
.icon-selector__label {
  margin-top: 10px;
}
.section-navigation-button {
  width: 40px;
  height: 40px;
  background-image: url("/images/icons/arrow-circle-black.svg");
  background-position: center;
  background-repeat: no-repeat;
}
.section-navigation-button--left {
  transform: rotate(90deg);
}
.section-navigation-button--right {
  transform: rotate(-90deg);
}
.dashboard-section-card-row {
  display: flex;
  flex-wrap: wrap;
}
.dashboard-section-card-container {
  width: 33%;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
}
.dashboard-section-card {
  width: 100%;
  background-color: white;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ECEBF0;
}
.dashboard-section-card__title {
  width: 100%;
  color: #2e1a47;
  margin-top: 0;
  margin-bottom: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dashboard-section-card__icon {
  width: 140px;
  height: 140px;
  margin-bottom: 20px;
  background-size: 70px;
  background-repeat: no-repeat;
  background-position: center;
}
.dashboard-section-card__action {
  width: 100%;
  display: flex;
  align-items: start;
  margin-bottom: 5px;
  text-align: left;
  cursor: pointer;
}
.dashboard-section-card__action__checkbox {
  position: relative;
  width: 20px;
  height: 20px;
  background-color: #f6f5fa;
  border-radius: 3px;
  border: 2px solid #2e1a47;
  margin-right: 10px;
  margin-top: 2px;
  transition: background-color 0.2s;
}
.dashboard-section-card__action__checkbox:after {
  content: "";
  position: absolute;
  width: 4px;
  height: 10px;
  left: 5px;
  top: 0px;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  transform: scale(0) rotate(0deg);
  transition: transform 0.2s;
}
.dashboard-section-card__action:hover .dashboard-section-card__action__checkbox {
  background-color: rgba(46, 26, 71, 0.3);
}
.dashboard-section-card__action:hover .dashboard-section-card__action__checkbox.dashboard-section-card__action__checkbox--active {
  background-color: rgba(46, 26, 71, 0.7);
}
.dashboard-section-card__action__checkbox--active {
  background-color: #2e1a47;
}
.dashboard-section-card__action__checkbox--active:after {
  transform: scale(1) rotate(45deg);
}
.dashboard-section-card__action__text {
  color: #999999;
  flex: 1;
}
.dashboard-section-card__action:hover .dashboard-section-card__action__text {
  text-decoration: underline;
}
.dashboard-section-card__button-container {
  width: 100%;
  margin-top: auto;
  padding-top: 10px;
}
@media only screen and (max-width: 1200px) {
  .dashboard-section-card-row {
    display: block;
  }
  .dashboard-section-card-container {
    width: 100%;
    max-width: 500px;
    margin: 10px auto;
  }
}
.overlay-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 30;
}
.overlay-content-container {
  flex: 1 1 auto;
  max-height: 100%;
  max-width: 1000px;
  overflow: auto;
}
.dashboard-company-name-box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: white;
  font-size: 26px;
  text-align: center;
  font-weight: bold;
  background-color: #2e1a47;
  background-image: url("/images/koru-dim.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.dashboard-location-box {
  padding: 20px;
  padding-left: 70px;
  background-color: #999999;
  color: white;
  background-image: url("/images/icons/location-marker-white.svg");
  background-position: 20px center;
  background-size: 40px;
  background-repeat: no-repeat;
}
.dashboard-action-progress {
  width: 100%;
  height: 200px;
}
.dashboard-action-progress__circle {
  position: relative;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 0 50px;
  background-image: url("/images/dots-and-lines.svg");
  background-repeat: no-repeat;
}
.dashboard-action-progress__circle__text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #2e1a47;
}
.link {
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.2s;
}
.link--primary {
  color: #2e1a47;
}
.link--primary:hover {
  color: #714ba0;
}
.mobile-nav-panel {
  display: none;
  position: absolute;
  left: 0;
  top: 130px;
  width: 360px;
  max-width: 100%;
  background-color: #2e1a47;
  font-size: 16px;
  z-index: 5;
}
.mobile-nav-panel__row {
  box-sizing: border-box;
  width: 100%;
  padding: 20px 26px;
  border-bottom: 1px solid white;
  transition: color 0.2s;
}
.mobile-nav-panel__row:hover {
  color: white;
}
.mobile-nav-panel__content {
  display: flex;
  flex-direction: column;
  padding: 26px;
}
.mobile-nav-panel__expansion {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  padding-top: 10px;
}
.mobile-nav-panel__social-icon {
  width: 24px;
  padding-right: 6px;
  vertical-align: middle;
}
.mobile-nav-panel__link {
  box-sizing: border-box;
  width: 100%;
  margin: 5px 0;
  color: white;
  text-decoration: none;
  font-weight: 300;
}
.mobile-nav-panel__link--expansion {
  padding: 6px 0;
}
.mobile-nav-panel__link__text {
  display: inline-block;
  vertical-align: middle;
}
.mobile-nav-panel__expansion-button {
  width: 12px;
  margin-left: 10px;
}
@media only screen and (max-width: 960px) {
  .mobile-nav-panel {
    display: block;
    top: 116px;
  }
}
@media only screen and (max-width: 640px) {
  .mobile-nav-panel {
    display: block;
    top: 51px;
    width: 100%;
  }
}
.percentage-circle {
  display: block;
}
.percentage-circle__background-circle {
  fill: none;
  stroke: rgba(0, 0, 0, 0.1);
  stroke-width: 1.5;
}
.percentage-circle__line {
  fill: none;
  stroke: #2e1a47;
  stroke-width: 1.5;
  stroke-linecap: round;
  transition: stroke-dasharray 1s;
}
.dashboard-section-results__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dashboard-section-results__row__name {
  color: #2e1a47;
  width: 140px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dashboard-section-results__row__progress-bar {
  width: calc(100% - 190px);
  height: 10px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.1);
}
.dashboard-section-results__row__progress-bar__progress {
  height: 10px;
  border-radius: 5px;
  animation: progress-bar-line-animation 1s ease-out forwards;
  transition: width 0.2s, background-color 0.2s;
}
.dashboard-section-results__row__percentage {
  width: 50px;
  font-weight: 600;
  text-align: right;
  transition: color 0.2s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@keyframes progress-bar-line-animation {
  from {
    width: 0;
  }
}
.alert-banner {
  padding: 20px;
  padding-left: 80px;
  background-color: #999999;
  color: white;
  background-image: url("/images/icons/info-circle-white.svg");
  background-size: 40px;
  background-position: 20px center;
  background-repeat: no-repeat;
  font-size: 16px;
  border-radius: 5px;
}
.vertical-line {
  width: 1px;
  background-color: #d8d8d8;
}
.horizontal-line {
  height: 1px;
  background-color: #d8d8d8;
  margin: 0 auto;
}
.horizontal-line--width-extra-small {
  max-width: 300px;
}
.horizontal-line--medium {
  height: 2px;
}
.horizontal-line--thick {
  height: 4px;
}
.horizontal-line--primary {
  background-color: #2e1a47;
}
.video-frame {
  width: 100%;
  height: 540px;
}
@media (max-width: 900px) {
  .video-frame {
    height: 500px;
  }
}
@media (max-width: 800px) {
  .video-frame {
    height: 400px;
  }
}
@media (max-width: 700px) {
  .video-frame {
    height: 350px;
  }
}
@media (max-width: 600px) {
  .video-frame {
    height: 300px;
  }
}
@media (max-width: 500px) {
  .video-frame {
    height: 250px;
  }
}
@media (max-width: 400px) {
  .video-frame {
    height: 200px;
  }
}
.color-green {
  color: #6C8849;
}
.bg-green {
  background-color: #6C8849;
}
.color-dark-green {
  color: #59713a;
}
.bg-dark-green {
  background-color: #59713a;
}
.color-light-green {
  color: #8bae5f;
}
.bg-light-green {
  background-color: #8bae5f;
}
.color-primary {
  color: #2e1a47;
}
.bg-primary {
  background-color: #2e1a47;
}
.color-dark-primary {
  color: #1f122f;
}
.bg-dark-primary {
  background-color: #1f122f;
}
.color-light-primary {
  color: #714ba0;
}
.bg-light-primary {
  background-color: #714ba0;
}
.color-background {
  color: #f6f5fa;
}
.bg-background {
  background-color: #f6f5fa;
}
.color-darker-background {
  color: #edecf2;
}
.bg-darker-background {
  background-color: #edecf2;
}
.color-text {
  color: #3d3942;
}
.bg-text {
  background-color: #3d3942;
}
.color-black {
  color: #3d3942;
}
.bg-black {
  background-color: #3d3942;
}
.color-white {
  color: white;
}
.bg-white {
  background-color: white;
}
.color-grey {
  color: #999999;
}
.bg-grey {
  background-color: #999999;
}
.color-darker-grey {
  color: #5F5D5D;
}
.bg-darker-grey {
  background-color: #5F5D5D;
}
.color-dark-grey {
  color: #303133;
}
.bg-dark-grey {
  background-color: #303133;
}
.color-light-grey {
  color: #d8d8d8;
}
.bg-light-grey {
  background-color: #d8d8d8;
}
.color-grey-border {
  color: #ECEBF0;
}
.bg-grey-border {
  background-color: #ECEBF0;
}
.color-red {
  color: #a94242;
}
.bg-red {
  background-color: #a94242;
}
.color-purple {
  color: #2e1a47;
}
.bg-purple {
  background-color: #2e1a47;
}
.color-blue {
  color: #007eb0;
}
.bg-blue {
  background-color: #007eb0;
}
.color-light-purple {
  color: #B6B2CC;
}
.bg-light-purple {
  background-color: #B6B2CC;
}
.color-golden {
  color: #F7981C;
}
.bg-golden {
  background-color: #F7981C;
}
.margin-auto {
  margin: auto;
}
.margin-none {
  margin: 0;
}
.margin-extra-small {
  margin: 5px;
}
.margin-small {
  margin: 10px;
}
.margin-medium {
  margin: 25px;
}
.margin-large {
  margin: 50px;
}
.margin-extra-large {
  margin: 100px;
}
.margin-left-auto {
  margin-left: auto;
}
.margin-left-none {
  margin-left: 0;
}
.margin-left-extra-small {
  margin-left: 5px;
}
.margin-left-small {
  margin-left: 10px;
}
.margin-left-medium {
  margin-left: 25px;
}
.margin-left-large {
  margin-left: 50px;
}
.margin-left-extra-large {
  margin-left: 100px;
}
.margin-right-auto {
  margin-right: auto;
}
.margin-right-none {
  margin-right: 0;
}
.margin-right-extra-small {
  margin-right: 5px;
}
.margin-right-small {
  margin-right: 10px;
}
.margin-right-medium {
  margin-right: 25px;
}
.margin-right-large {
  margin-right: 50px;
}
.margin-right-extra-large {
  margin-right: 100px;
}
.margin-top-auto {
  margin-top: auto;
}
.margin-top-none {
  margin-top: 0;
}
.margin-top-extra-small {
  margin-top: 5px;
}
.margin-top-small {
  margin-top: 10px;
}
.margin-top-medium {
  margin-top: 25px;
}
.margin-top-large {
  margin-top: 50px;
}
.margin-top-extra-large {
  margin-top: 100px;
}
.margin-bottom-auto {
  margin-bottom: auto;
}
.margin-bottom-none {
  margin-bottom: 0;
}
.margin-bottom-extra-small {
  margin-bottom: 5px;
}
.margin-bottom-small {
  margin-bottom: 10px;
}
.margin-bottom-medium {
  margin-bottom: 25px;
}
.margin-bottom-large {
  margin-bottom: 50px;
}
.margin-bottom-extra-large {
  margin-bottom: 100px;
}
.margin-x-auto {
  margin-left: auto;
  margin-right: auto;
}
.margin-x-none {
  margin-left: 0;
  margin-right: 0;
}
.margin-x-extra-small {
  margin-left: 5px;
  margin-right: 5px;
}
.margin-x-small {
  margin-left: 10px;
  margin-right: 10px;
}
.margin-x-medium {
  margin-left: 25px;
  margin-right: 25px;
}
.margin-x-large {
  margin-left: 50px;
  margin-right: 50px;
}
.margin-x-extra-large {
  margin-left: 100px;
  margin-right: 100px;
}
.margin-y-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.margin-y-none {
  margin-top: 0;
  margin-bottom: 0;
}
.margin-y-extra-small {
  margin-top: 5px;
  margin-bottom: 5px;
}
.margin-y-small {
  margin-top: 10px;
  margin-bottom: 10px;
}
.margin-y-medium {
  margin-top: 25px;
  margin-bottom: 25px;
}
.margin-y-large {
  margin-top: 50px;
  margin-bottom: 50px;
}
.margin-y-extra-large {
  margin-top: 100px;
  margin-bottom: 100px;
}
.padding-auto {
  padding: auto;
}
.padding-none {
  padding: 0;
}
.padding-extra-small {
  padding: 5px;
}
.padding-small {
  padding: 10px;
}
.padding-medium {
  padding: 25px;
}
.padding-large {
  padding: 50px;
}
.padding-extra-large {
  padding: 100px;
}
.padding-left-auto {
  padding-left: auto;
}
.padding-left-none {
  padding-left: 0;
}
.padding-left-extra-small {
  padding-left: 5px;
}
.padding-left-small {
  padding-left: 10px;
}
.padding-left-medium {
  padding-left: 25px;
}
.padding-left-large {
  padding-left: 50px;
}
.padding-left-extra-large {
  padding-left: 100px;
}
.padding-right-auto {
  padding-right: auto;
}
.padding-right-none {
  padding-right: 0;
}
.padding-right-extra-small {
  padding-right: 5px;
}
.padding-right-small {
  padding-right: 10px;
}
.padding-right-medium {
  padding-right: 25px;
}
.padding-right-large {
  padding-right: 50px;
}
.padding-right-extra-large {
  padding-right: 100px;
}
.padding-top-auto {
  padding-top: auto;
}
.padding-top-none {
  padding-top: 0;
}
.padding-top-extra-small {
  padding-top: 5px;
}
.padding-top-small {
  padding-top: 10px;
}
.padding-top-medium {
  padding-top: 25px;
}
.padding-top-large {
  padding-top: 50px;
}
.padding-top-extra-large {
  padding-top: 100px;
}
.padding-bottom-auto {
  padding-bottom: auto;
}
.padding-bottom-none {
  padding-bottom: 0;
}
.padding-bottom-extra-small {
  padding-bottom: 5px;
}
.padding-bottom-small {
  padding-bottom: 10px;
}
.padding-bottom-medium {
  padding-bottom: 25px;
}
.padding-bottom-large {
  padding-bottom: 50px;
}
.padding-bottom-extra-large {
  padding-bottom: 100px;
}
.padding-x-auto {
  padding-left: auto;
  padding-right: auto;
}
.padding-x-none {
  padding-left: 0;
  padding-right: 0;
}
.padding-x-extra-small {
  padding-left: 5px;
  padding-right: 5px;
}
.padding-x-small {
  padding-left: 10px;
  padding-right: 10px;
}
.padding-x-medium {
  padding-left: 25px;
  padding-right: 25px;
}
.padding-x-large {
  padding-left: 50px;
  padding-right: 50px;
}
.padding-x-extra-large {
  padding-left: 100px;
  padding-right: 100px;
}
.padding-y-auto {
  padding-top: auto;
  padding-bottom: auto;
}
.padding-y-none {
  padding-top: 0;
  padding-bottom: 0;
}
.padding-y-extra-small {
  padding-top: 5px;
  padding-bottom: 5px;
}
.padding-y-small {
  padding-top: 10px;
  padding-bottom: 10px;
}
.padding-y-medium {
  padding-top: 25px;
  padding-bottom: 25px;
}
.padding-y-large {
  padding-top: 50px;
  padding-bottom: 50px;
}
.padding-y-extra-large {
  padding-top: 100px;
  padding-bottom: 100px;
}
.align-left {
  text-align: left;
}
.align-center {
  text-align: center;
}
.align-right {
  text-align: right;
}
@media only screen and (max-width: 400px) {
  .align-center-less-than-extra-small {
    text-align: center;
  }
}
@media only screen and (max-width: 600px) {
  .align-center-less-than-small {
    text-align: center;
  }
}
@media only screen and (max-width: 800px) {
  .align-center-less-than-medium {
    text-align: center;
  }
}
@media only screen and (max-width: 960px) {
  .align-center-less-than-large {
    text-align: center;
  }
}
@media only screen and (max-width: 1200px) {
  .align-center-less-than-extra-large {
    text-align: center;
  }
}
.hide {
  display: none;
}
@media only screen and (max-width: 400px) {
  .hide-less-than-extra-small {
    display: none;
  }
}
@media only screen and (min-width: 401px) {
  .hide-more-than-extra-small {
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  .hide-less-than-small {
    display: none;
  }
}
@media only screen and (min-width: 601px) {
  .hide-more-than-small {
    display: none;
  }
}
@media only screen and (max-width: 800px) {
  .hide-less-than-medium {
    display: none;
  }
}
@media only screen and (min-width: 801px) {
  .hide-more-than-medium {
    display: none;
  }
}
@media only screen and (max-width: 960px) {
  .hide-less-than-large {
    display: none;
  }
}
@media only screen and (min-width: 961px) {
  .hide-more-than-large {
    display: none;
  }
}
@media only screen and (max-width: 1200px) {
  .hide-less-than-extra-large {
    display: none;
  }
}
@media only screen and (min-width: 1201px) {
  .hide-more-than-extra-large {
    display: none;
  }
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cursor-pointer {
  cursor: pointer;
}
.absolute-fill {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.transition-color {
  transition: color 0.2s;
}
.transition-background-color {
  transition: background-color 0.2s;
}
.weight-light {
  font-weight: 300;
}
.weight-regular {
  font-weight: 400;
}
.weight-medium {
  font-weight: 500;
}
.weight-bold {
  font-weight: 600;
}
.border-bottom {
  border-bottom: 1px solid #ECEBF0;
}
