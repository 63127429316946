.box {
  background-color: white;
  border-radius: 5px;
}

.box--offset-outline {
  position: relative;

  &:after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: -10px;
    left: -10px;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    border: 5px solid @colors[primary];
    border-radius: 10px;
  }
}
