.dashboard-company-name-box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: white;
  font-size: 26px;
  text-align: center;
  font-weight: bold;
  background-color: @colors[primary];
  background-image: url("/images/koru-dim.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
