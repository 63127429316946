.dashboard-section-card-row {
  display: flex;
  flex-wrap: wrap;
}

.dashboard-section-card-container {
  width: 33%;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
}

.dashboard-section-card {
  width: 100%;
  background-color: white;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid @colors[grey-border];
}

.dashboard-section-card__title {
  width: 100%;
  color: @colors[purple];
  margin-top: 0;
  margin-bottom: 20px;
  .truncate();
}

.dashboard-section-card__icon {
  width: 140px;
  height: 140px;
  margin-bottom: 20px;
  background-size: 70px;
  background-repeat: no-repeat;
  background-position: center;
}

.dashboard-section-card__action {
  width: 100%;
  display: flex;
  align-items: start;
  margin-bottom: 5px;
  text-align: left;
  cursor: pointer;
}

.dashboard-section-card__action__checkbox {
  position: relative;
  width: 20px;
  height: 20px;
  background-color: @colors[background];
  border-radius: 3px;
  border: 2px solid @colors[purple];
  margin-right: 10px;
  margin-top: 2px;
  transition: background-color @transition-duration;

  &:after {
    content: "";
    position: absolute;
    width: 4px;
    height: 10px;
    left: 5px;
    top: 0px;
    border-right: 2px solid white;
    border-bottom: 2px solid white;
    transform: scale(0) rotate(0deg);
    transition: transform @transition-duration;
  }

  .dashboard-section-card__action:hover & {
    background-color: rgba(@colors[primary], 0.3);

    &.dashboard-section-card__action__checkbox--active {
      background-color: rgba(@colors[primary], 0.7);
    }
  }
}

.dashboard-section-card__action__checkbox--active {
  background-color: @colors[primary];

  &:after {
    transform: scale(1) rotate(45deg);
  }
}

.dashboard-section-card__action__text {
  color: @colors[grey];
  flex: 1;

  .dashboard-section-card__action:hover & {
    text-decoration: underline;
  }
}

.dashboard-section-card__button-container {
  width: 100%;
  margin-top: auto;
  padding-top: 10px;
}

@media only screen and (max-width: 1200px) {
  .dashboard-section-card-row {
    display: block;
  }

  .dashboard-section-card-container {
    width: 100%;
    max-width: 500px;
    margin: 10px auto;
  }
}
