input {
  box-sizing: border-box;
  width: 100%;
  padding: 6px 14px;
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid white;
  outline: none;
  font-family: @fonts[primary];
  transition: border-color @transition-duration;
  background-color: white;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &::placeholder {
    color: @colors[grey];
  }

  &:focus {
    border-color: @colors[primary];
  }
}

.input-dim {
  input {
    background-color: @colors[background];
    border-color: @colors[background];

    &:focus {
      border-color: @colors[primary];
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
