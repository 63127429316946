@font-weights: {
  light: 300;
  regular: 400;
  medium: 500;
  bold: 600;
}

each(@font-weights, .(@weight, @name) {
  .weight-@{name} {
    font-weight: @weight;
  }
});
