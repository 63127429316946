.section {
  width: 100%;
  padding: 50px 30px;
}

.section--padding-small {
  padding-top: 20px;
  padding-bottom: 20px;
}

.section__content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.section--background-icons {
  background-image:
    url("/images/background-icons/innovation-light-grey.svg"),
    url("/images/background-icons/health-light-grey.svg"),
    url("/images/background-icons/finance-light-grey.svg"),
    url("/images/background-icons/people-light-grey.svg"),
    url("/images/background-icons/clipboard-light-grey.svg");
  background-size:
    200px,
    100px,
    200px,
    200px,
    160px;
  background-position:
    -60px -30px,
    50px 90%,
    100% -30px,
    60% 105%,
    95% 90%;
  background-repeat: no-repeat;
}

each(@content-widths, .(@width, @name) {
  .section__content--@{name} {
    max-width: @width;
  }
});

@media only screen and (max-width: 800px) {
  .section--background-icons {
    background-image:
      url("/images/background-icons/innovation-light-grey.svg"),
      url("/images/background-icons/finance-light-grey.svg"),
      url("/images/background-icons/people-light-grey.svg");
    background-size:
      200px,
      200px,
      200px;
    background-position:
      -60px -50px,
      110% 50%,
      -20px 98%;
  }
}
