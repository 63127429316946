.column-container {
  display: flex;
}

.column-container--align-center {
  align-items: center;
}

.column-container--align-end {
  align-items: flex-end;
}

.column-container--align-stretch {
  align-items: stretch;
}

.column-container--wrap {
  flex-wrap: wrap;
}

.column-container--justify-center {
  justify-content: center;
}

.column-container--justify-space-between {
  justify-content: space-between;
}

each(@content-widths, .(@width, @name) {
  @media only screen and (max-width: @width) {
    .column-container--@{name}-cut-off {
      flex-direction: column;
      align-items: center;

      & > .column {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
});
