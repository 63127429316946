.header {
  position: relative;
  width: 100%;
  background-color: white;
  font-size: 16px;
  z-index: @z-indexes[header];
}

.header__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid @colors[darker-background];
}

.header__tagline {
  font-size: 18px;
  font-style: italic;
  text-align: right;
  font-weight: 400;
}

.header__logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 45px;
}

.header__logo-link {
  line-height: 0;
}

.header__logo {
  max-width: 100%;
  max-height: 80px;
  cursor: pointer;
}

.header__button {
  width: 128px;
  height: 128px;
  background-position: center;
  background-size: 32px;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: background-color @transition-duration;

  &:hover {
    background-color: @colors[light-purple];
  }
}

.header__menu-button {
  display: none;
  background-image: url("/images/icons/menu-purple.svg");
}

.header__menu-button--open {
  background-image: url("/images/icons/cross-purple.svg");
}

.header__search-button {
  background-image: url("/images/icons/search-purple.svg");
}

.header__nav-item {
  min-height: 90px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: background-color @transition-duration;
  color: @colors[purple];
  text-decoration: none;
  padding: 0 10px;
  background-color: white;
  font-size: 18px;
  box-sizing: border-box;

  &:hover {
    background-color: @colors[darker-background];
  }

  & + & {
    border-left: 1px solid @colors[darker-background];
  }
}

.header__nav-item--expandable {
  position: relative;

  &:hover {
    background-color: white;
  }
}

.header__nav-item__dropdown-arrow {
  width: 12px;
  margin-left: 6px;
}

.header__nav-item__dropdown {
  display: none;
  position: absolute;
  left: -1px;
  right: -1px;
  top: 100%;
  border: 1px solid @colors[darker-background];
}

.header__nav-item--expandable:hover .header__nav-item__dropdown,
.header__nav-item--expandable:focus .header__nav-item__dropdown {
  display: block;
}

.header__nav-item__dropdown__item {
  display: flex;
  min-height: 75px;
  padding: 20px;
  text-align: left;
  align-items: center;
  background-color: white;
  text-decoration: none;
  color: @colors[purple];
  border-bottom: 1px solid @colors[darker-background];
  font-size: 15px;
  box-sizing: border-box;
  transition: background-color @transition-duration;

  &:hover {
    background-color: @colors[darker-background];
  }
}

@media only screen and (max-width: 960px) {
  .header {
    height: 115px;
  }

  .header__logo-wrapper {
    padding: 0;
  }

  .header__logo {
    max-height: 70px;
    margin: 0;
  }

  .header__tagline {
    display: none;
  }

  .header__button {
    width: 115px;
    height: 115px;
  }

  .header__menu-button {
    display: block;
  }

  .header__row--nav {
    display: none;
  }
}

@media only screen and (max-width: 640px) {
  .header {
    height: 50px;
  }

  .header__button {
    width: 50px;
    height: 50px;
    background-size: 20px;
  }

  .header__logo-wrapper {
    height: 50px;
    min-height: 50px;
  }

  .header__logo {
    max-height: 42px;
  }
}
