textarea {
  box-sizing: border-box;
  width: 100%;
  min-height: 60px;
  resize: vertical;
  padding: 6px 14px;
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid white;
  outline: none;
  font-family: @fonts[primary];
  transition: border-color @transition-duration;
  background-color: white;

  &::placeholder {
    color: @colors[grey];
  }

  &:focus {
    border-color: @colors[primary];
  }
}

.input-dim {
  textarea {
    background-color: @colors[background];
    border-color: @colors[background];

    &:focus {
      border-color: @colors[primary];
    }
  }
}
